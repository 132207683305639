.section-1 {
  background-image: url(/assets/images/HeroBanner.jpg);
  background-position: top left;
  background-size: cover;
  padding-top: calc(#{$navbar-height} + #{$section-padding-v});
  padding-bottom: calc(#{$navbar-height} + #{$section-padding-v});
  position: relative;

  @include tablet {
    background-position: top right;
  }

  h1 {
    span {
      font-weight: 500;
      font-size: 0.65em;
      line-height: 1.15em;
      display: block;
      padding-top: 0.5em;
    }
  }

  .overlay {
    background: linear-gradient(90deg, #007FC1 0%, rgba(2,38,64,0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
