.section-5 {

  background: $lightest-gray;

  @include desktop {
    padding-top: 0em;
  }
  p {
    font-size: 20px;
    line-height: 32px;
  }

  @include desktop {
    margin-right: $gap;
  }

  .section-container {
    @include desktop {
      padding-left: $gap;
    }
  }


  .container {
    @include desktop {

    }
  }
}
