html, body {
  font-family: $proxima, sans-serif;
}

.wf-loading {
  h1, h2, h3, h4, h5, h6, p, span, a {
    opacity: 0;
    transition: opacity 300ms;
  }
}

.wf-inactive, .wf-active {
  h1, h2, h3, h4, h5, h6, p, span, a {
    transition: opacity 300ms;
    opacity: 1;
  }
}
h1 {

  color: #FFFFFF;
  font-family: $proxima, sans-serif;
  font-weight: bold;
  line-height: 48px;
  text-shadow: 0 2px 3px rgba(0,0,0,0.3);
  font-size: 48px;
  text-align: center;

  @include tablet {
    text-align: left;
  }

  @include desktop {
    font-size: 72px;
    line-height: 72px;
  }
}

h2 {
	color: $light-blue;
	font-family: $proxima, sans-serif;
	font-weight: bold;
  text-align: center;
  font-size: 36px;
  line-height: 46px;

  @include desktop {
    font-size: 48px;
    line-height: 58px;
    text-align: left;
  }
}

h3 {
	color: $white;
	font-size: 30px;
  line-height: 36px;
	font-weight: bold;
  text-align: center;

  @include desktop {
    text-align: left;
  }
}

p {
  color: $black;
  font-size: 16px;
  line-height: 26px;

  font-size: 20px;
  line-height: 32px;

  padding: 1em 0;
  text-align: center;

  @include desktop {
    font-size: 20px;
    line-height: 32px;
    text-align: left;
  }
}

a {
  color: $blue;
}
