$imageHeight: 362px;
$cardHeight: 480px;
$cardWidth: 320px;

.section-4 {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  .section {
    padding-bottom: 3em;
    padding-top: 3em;

    @include tablet {
      margin-right: $gap;
    }

  }

  .course-offerings {
    padding-top: 0em;

    @include tablet {
      padding-top: 12em;
    }
  }

  .image-container {
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    @include tablet {
      background-image: url(/assets/images/it-guy.jpg);
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;

      @include tablet {
        background-image: linear-gradient(90deg, rgba(2,38,64,0) 90%, $white 99%);
      }
    }
  }

  .filter-container {
    position: relative;
    height: 51px;
    background: rgba(2,38,64, 0.67);
    display: none;
    padding-left: $gap;

    @include desktop {
      margin-right: $gap;
      display: block;
    }


    .filter-nav {
      line-height: 51px;
      text-transform: uppercase;
      li {
        display: inline-block;
      }

      a {
        color: rgba(255, 255, 255, 0.6);
        display: block;
        padding: 0 1.5em;
        position: relative;
        background: transparent;
        transition: background 300ms;

        &::after {
          content: ' ';
          position: absolute;
          height: 3px;
          transition: bottom 300ms, background 300ms, color 300ms;
          left: 1.5em;
          right: 1.5em;
          bottom: -3px;
          background: transparent;
        }

        &:hover,
        &.active {
          background: $white;
          color: $dark-blue;

          &::after {
            bottom: 25%;
            background: $orange;
          }
        }
      }
    }
  }
}


.section.filter-items-container {
  background: $lightest-gray;

  @include desktop {
    margin-right: $gap;
  }

  .filter-items {
    @include desktop {
      padding-left: $gap;
    }
  }

  .card-container {
    display: block;

    @include tablet {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .card-item {
      width: 100%;
      opacity: 1;
      //transition: opacity 300ms;
      height: $cardHeight;
      perspective: 1500px;
      margin: 1em 0;

      &.hide {
        opacity: 0;
        display: none;
      }

      @include tablet {
        width: 50%;
      }

      @include desktop {
        width: 33.33%;
        max-width: 33.33%;
      }

      @media only screen and (min-width: 1430px)  {
        width: 25%;
        max-width: 25%;
      }



      .card {
        margin: 1em;
        display: flex;
        flex-direction: column;
        height: $cardHeight;
        position: relative;
        box-shadow: none;
        background-color: transparent;
        cursor: default;


        .front {
          background-size: cover;
          background-position: center;
          box-shadow: 0 0 2px 2px rgba(0,0,0,0.15);
        }

        .back {
          box-shadow: 0 0 2px 2px rgba(0,0,0,0.15);
        }

        .image,
        .content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .image {
          height: $imageHeight;
          background: $gray;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          //z-index: 2;
        }

        .content {
          height: $cardHeight;
          padding: 2em;
        }

        .title-container {
          bottom: 0;
          position: absolute;
          left: 0;
          right: 0;
          background: $white;
        }
      }
    }

    .title {
      padding: 1.5em 1em;
      text-align: center;
      color: $dark-blue;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;

    }
  }
}


.flip-container {

  perspective: 1000px;
  transform-style: preserve-3d;

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;

    .front, .back {
      backface-visibility: hidden;
      transition: 0.6s;
      transform-style: preserve-3d;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $cardHeight;
      background: $white;

    }

    .front {
      transform: rotateY(0deg);
      z-index: 2;
    }

    .back {
      transform: rotateY(-180deg);
    }
  }

  //&:hover,
  &.hover {
    .front {
      transform: rotateY(180deg);
    }

    .back {
      transform: rotateY(0deg);

    }
  }

  /* Sorry about the code dup, I couldn't figure out how to chain these at the time */
  @supports (-ms-ime-align:auto) and (not (-ms-accelerator:true)) {
    .flipper {
      transition: 0s;

      .front,
      .back {
        transition: 0s;
      }
      .back {
        transform: rotateY(0deg);
      }
    }

    // &:hover,
    &.hover {
      .front {
        transform: rotateY(0deg);
        z-index: -1;
        display: none;
      }

      .back {
        transform: rotateY(0deg);

      }
    }
  }

  /* Sorry about the code dup, I couldn't figure out how to chain these at the time */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)  {

    .flipper {
      transition: 0s;

      .front,
      .back {
        transition: 0s;
      }
      .back {
        transform: rotateY(0deg);
      }
    }

    // &:hover,
    &.hover {
      .front {
        transform: rotateY(0deg);
        z-index: -1;
        display: none;
      }

      .back {
        transform: rotateY(0deg);

      }
    }
  }
}
