.section-6 {
  background: url(/assets/images/FooterImage.jpg);
  background-size: cover;
  position: relative;

  a.link {
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }

  .get-started {
    position: absolute;
    top: -6rem;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @include desktop {
      left: 0;
      transform: translateY(-50%);
    }
  }

  p {
    color: $white;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    position: relative;
  }

  .phone-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include desktop {
      display: inline-flex;
    }

    p {
      display: inline;
    }

    .phone {
      color: $white;
      font-size: 18px;
      font-weight: 600;
      line-height: 29px;
    }

    span.or {
      color: white;
      display: inline;
      text-align: center ;

      @include desktop {
        text-align: left;
      }
    }
  }


  .columns {
    .column {
      display: flex;
      flex-direction: column;
      padding: 0 0 3em 0;

      @include tablet {
        &:nth-child(1) {
          padding: 1em;
          margin-left: -1em;
        }

        &:nth-child(2) {
          padding: 1em;
        }
      }

      &:nth-child(3) {
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 0;

        @include desktop {
          align-items: center;
          padding-bottom: 3em;
        }
      }
    }
  }

  .button.ghost {
    display: inline-flex;
    align-items: center;

    .orange-arrow {
      margin-top: -2px;
    }
  }

}

.footer-links {
  margin-top: 3em;
  color: white;
  text-align: center;

  @include desktop {
    text-align: right;
  }

  li {
    display: inline-block;

    &:last-child {
      a {
        padding-right: 0;

        &::after {
            content: '';
            padding: 0;
        }
      }
    }

    a, strong {
      color: white;
      padding: 1em 0 1em 1em;

      &::after {
        content: '|' ;
        padding-left: 1em;
      }
    }
  }

}

#footer-logo {
  margin: 0 auto;

  @include desktop {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
