.section-3 {
  background: $white;

  .content-container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row;
      max-height: 350px;
    }

    .carousel-controls-container {
      width: 100%;

      @include tablet {
        width: 25%;
      }

      @include desktop {
        width: 16.66667%;
      }
    }

    .carousels {
      width: 100%;
      z-index: 1;
      display: flex;
      position: relative;

      .main-carousel{
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

          @include tablet {
            transform: translateY(50%);
          }

          @include desktop {
            width: 83.33337%;
            transform: translateY(0);
          }
        }
      }

      @include tablet {
        width: 75%;
        transform: translateY(-50%);
      }

      @include desktop {
        width: 83.33337%;
        transform: translateY(-175px);
      }
    }

    .carousel-container {
      width: 100%;
      max-width: $carousel-max-width;
      flex-shrink: 0;

      @include desktop {
        width: 80%;
      }
    }

    .mini-carousel {
      width: 0;
      transform: translateY(175px);
      height: calc(100% + 35px);
      margin-left: -1px;

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $dark-blue;
        opacity: 0.7;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          height: calc(100% - 210px);
          max-height: 100%;
        }

      }

      @include desktop {
        width: $carousel-max-width;
        min-width: 20%;
      }

      .card {
        position: relative;



        @include tablet {
          max-height: 385px;
          height: 385px;
        }

        .img {
          height: 386px;
        }

      }
    }

  }

  .card {
    z-index: 1;
    overflow: hidden;
    width: 100%;
    background-color: transparent;
    // height: 560px;
    max-height: 600px;
    min-height: 560px;

    @include tablet {
      max-height: 560px;
      height: 560px;
    }

    .img {
      display: block;
      flex-shrink: 1;
      background-size: cover;
      width: 100%;
      height: 370px;

      @include tablet {
        height: 460px;
      }
      @include desktop {
        height: 560px;
      }
    }

    .card-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .card-content {
      flex-grow: 1;
      width: 100%;
      background: white;
      z-index: 1;
      position: relative;
      color: $white;
      bottom: 0;

      .watermark {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          max-height: 100%;
          margin-right: 3em;
        }
      }

      @include desktop {
        transform: translate(-100%, 100%);
        transition: transform 300ms 0ms, left 300ms 0ms, opacity 300ms 0ms;
        opacity: 0;
        left: 0;

      }

      &.up {
        transform: translate(0);
        opacity: 1;
      }

      &.right {
        transform: translate(-100%, 100%);
      }

      @include tablet {
        position: absolute;
      }

      @include desktop {
        width: 62.5%;
      }

      &.light-blue {
        background: $light-blue;
      }

      &.orange {
        background: $orange;
      }

      &.dark-blue {
        background: $dark-blue;
      }

      &.gray {
        background: $gray;
      }

      .title {
        color: $white;
        display: block;
      }

      .subtitle {
        color: $white;
        opacity: 0.6;
      }

      .content {
        padding: 0.5rem 0 2rem;

        p {
          color: white;
          text-align: left;
          font-size: 16px;
          opacity: 0.85;
          padding: 0;
        }
      }

    }
  }
}
