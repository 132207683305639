.button {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 24px;
  padding: 1em 1.59em;
  letter-spacing: 1px;
  font-weight: 600;
  height: auto;
  line-height: 29px;
  display: flex;

  &.get-started {
    padding-right: 2em;
    background-color: $orange;
  }

  .orange-arrow,
  .white-arrow {
    width: 28px;
    margin-left: 0.5em;
  }

  .white-arrow {
    display: block;
  }

  .orange-arrow {
    display: none;
  }

  &:hover {
    border-color: $orange;
    color: $orange;
    background: $white;

    .orange-arrow {
      display: block;
    }

    .white-arrow {
      display: none;
    }
  }

  &.ghost {
    border: 2px solid $white;
    background: none;
    font-size: 18px;
    padding: 0.75em 1.5em;

    .orange-arrow,
    .white-arrow {
      width: 24px;
      margin-left: 0.5em;
    }

    .orange-arrow {
      display: block;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $orange;
      color: $orange;

      .orange-arrow {
        display: block;
      }
    }
  }
}
