
.navbar-container {
  width: 100%;
  z-index: 9999;
}
.container > .navbar {

  display: flex;
  justify-content: space-between;

  .navbar-brand {
    // position: relative;

    img {
      margin: 10px;
      height: 106px;
      width: 112px;
    }

    @include tablet {
      padding-left: 1rem;
    }

    .university {
      position: absolute;
      bottom: 0;
      text-transform: uppercase;
      color: #fff;
      transform: translateY(200%) translateX(-50%);
    }
  }
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 350px;
  bottom: 100%;

  &.open {
    .button.navbar-toggle {
      background: $dark-blue !important;
    }

    .nav-menu {
      transform: translateY(0);
      background: $dark-blue;
    }
    .navbar-toggle-container {
      .button.navbar-toggle {
        svg {
          // g {
          //   fill: red;
          // }
            width: 32px;
            height: 32px;


          &:nth-child(1) {
            transform: rotate(-45deg) translateY(-12px) translateX(-12px);
            margin-left: 1px;
          }

          &:nth-child(2) {
            transform: rotate(0deg) translateY(0) translateX(-16px);
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(45deg) translateY(12px) translateX(-12px);
            margin-left: 1px;
          }
        }
      }
    }

    &.scrolled {
      .navbar-toggle-container {
        .course-offerings {
          transform: translateY(-100%);
        }
      }
    }
  }

  &.scrolled {
    .navbar-toggle-container {
      .button.navbar-toggle,
      .course-offerings {
        background: rgba(2,38,64,0.76);
      }

      .course-offerings {
        transform: translateY(0);
      }
    }
  }

  .navbar-toggle-container {
    height: 80px;
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: flex-end;

    .button.navbar-toggle {
      width: 80px;
      height: 80px;
      background: rgba(0,0,0,0.0);
      transition: background 300ms 0ms;
      border: 0;
      padding: 0;


      svg {
        fill: white;
        position: absolute;
        left: 50%;
        width: 32px;
        //transform: rotate(0deg) translateX(5px) translateY(0px);
        opacity: 1;
        transition: opacity 300ms, transform 300ms, margin 300ms;
        fill: white;
        margin-left: 0;

        & > rect {
          fill: $white;
          transform-origin: 50%;
        }

        &:nth-child(1) {
          // margin-top: -8px;
          // margin-left: -16px;
          transform: rotate(0deg) translateX(-16px) translateY(-8px);
        }

        &:nth-child(2) {
          // margin-top: -0px;
          // margin-left: -16px;
          transform: rotate(0deg) translateX(-16px) translateY(0px);
        }

        &:nth-child(3) {
          // margin-top: 8px;
          // margin-left: -16px;
          transform: rotate(0deg) translateX(-16px) translateY(8px);
        }
      }
    }
  }

  .course-offerings {
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    padding: 2em;
    color: $white;
    background: rgba(0,0,0,0.0);
    transform: translateY(-100%);
    transition: transform 300ms;
  }

  .nav-menu {
    padding: 1.25em 2.75em;
    color: $white;
    transform: translateY(-150%);
    transition: transform 300ms;
    background: rgba(2,38,64,0.76);
    position: relative;
    //z-index: -1;

    li {
      font-size: 22px;
      font-weight: 600;

      a {
        padding: 0.5em 0 0.5em calc(2em - 8px);
        display: block;
        color: white;
        transition: color 300ms, border 300ms;
        margin-left: -2em;
        border-left: 8px solid transparent;
        margin-right: -8px;

        &:hover,
        &.active {
          color: $orange;
          border-left: 8px solid $orange;
        }
      }
    }
  }
}




.navbar-item.brand {
  // background: $dark-blue;
  // color: white;
  // font-weight: bold;
  // text-align: center;
  // height: 100%;
  // min-height: $navbar-height;
  // width: 205px;
  // justify-content: center;

}
