.section-2 {
  position: relative;
  display: flex;
  padding-top: $column-gap;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  .image-container {
    position: relative;
    width: 100%;

    .overlay {
      background: linear-gradient(180deg, rgba(2,38,64,0) 75%, #022640 100%);
      height: 100%;
    }

    @include tablet {
      width: 41.66667%;
      background-image: url(/assets/images/LeftBlock.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }

  .button-container {
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    display: flex;
    justify-content: center;

    @include tablet {
      justify-content: flex-end;
    }

    .button {
      display: flex;
      z-index: 1;
    }
  }

  .content-inner {
    @include tablet {
      max-width: (6 * $gap);
    }
  }

  .content {
    margin-top: -40px;
    padding: $gap 10px;
    background: $white;
    text-align: center;

    @include tablet {
      padding: ($gap * 0.75) ($gap / 2)  ($gap * 3);
      width: 58.33333%;
    }

    @include desktop {
      padding: $gap $gap ($gap * 3);
      text-align: left;
    }
  }

  .memorial {
    position: relative;
    margin-top: 2em;

    > h2 {
      padding-bottom: 1em;

      @include tablet {
        display: none;
      }
    }

    img {

      @include tablet {
        position: absolute;
        left: -80px;
        transform: translateX(-40%);
      }
    }

    .text {
      padding-bottom: 2em;

      h2 {
        display: none;
      }

      @include tablet {
        padding-left: $gap;
        display: block;

        h2 {
          display: block;
        }
      }
    }
  }
}
