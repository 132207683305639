@import 'flickity';

.carousel-container {
  min-height: 350px;

  @include tablet {
    max-height: 350px;
  }
}

.main-carousel {
  .flickity-viewport {
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.33);
  }
}
.mini-carousel {
  //background: $gray;
  height: 100%;

  .card {
    box-shadow: none;
  }

  .flickity-prev-next-button {
    display: none;
  }
}


.flickity-prev-next-button {
  display: none;
}


.carousel-controls-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-height: 350px;

}

.carousel-controls {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;

  @include tablet {
    justify-content: flex-end;
  }

  @include desktop {
    flex-direction: column-reverse;
    align-items: center;

    .carousel-timer {
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 1300px)  {
    flex-direction: row;
    margin-left: 1em;
    justify-content: flex-end;
  }
}

.flickity-page-dots {
  width: auto;
  position: static;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-timer-svg,
.carousel-play {
  width: 60px;
  display: block;
}

.carousel-timer {
  max-height: 60px;
  margin-left: 1em;
}

.carousel-timer-circle {
  stroke-dasharray: 138;
  stroke-dashoffset: 0;
}

.carousel-timer-circle.running {
  stroke-dasharray: 138;
  stroke-dashoffset: 138;
  // animation: dash 11.5s linear forwards;
  animation-iteration-count: 1;
}

[data-cmd="PAUSE"] {
  .carousel-play {
    display: none;
  }

}

[data-cmd="PLAY"] {
  .carousel-pause {
    display: none;
  }
}


@keyframes dash {
  from {
    stroke-dasharray: 138;
    stroke-dashoffset: 138;
  }

  to {
    stroke-dasharray: 138;
    stroke-dashoffset: 0;
  }
}
// .carousel .flickity-viewport {
//   width: 862px;
// }

// .carousel-cell {
//   width: 100%;
// }
